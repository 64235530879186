






















































































































































































































































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import {
  getUserAllTakeOffices,
  takeOfficeOp,
  personDetails,
  addOrEditPerson
} from '@/api/system/PersonApi';
import { getCompanys, getCompanyTreeData } from '@/api/system/OrganizationApi';
@Component({
  name: 'StatusForm'
})
export default class StatusForm extends Vue {
  // ref
  @Ref() readonly formRef;
  @Prop({ default: false }) private visible!: boolean;
  // 操作状态
  @Prop({ default: '' }) flag!: string;
  //   当前数据
  @Prop({ default: undefined }) currtent;
  //当前公司信息
  perosonCompanyList: any[] = [];
  //平台任职信息
  personPlatformList: any[] = [];
  // 更换主公司列表
  mainCompanyList: any[] = [];
  // 部门下拉列表
  deptListData: any[] = [];
  // 职务下拉列表
  dutyListData: any[] = [];
  // 表单信息
  personData: any = {};
  // 在职展示的数据
  onDeptList: any[] = [];
  // 在职展示的数据
  takeOffices: any[] = [];
  // 在职的原因
  takeOfficeReason: string = '';
  // 表单验证规则
  formRules: Record<any, any> = {
    mainCompany: {
      required: true,
      message: this.$t('lang_whether_the_main_company'),
      trigger: 'change'
    },
    all: {
      required: true,
      message:
        this.flag == 'out'
          ? this.$t('lang_please_select_resignation_operation')
          : this.$t('lang_please_select_delete_operation'),
      trigger: 'change'
    },
    synWechat: {
      required: true,
      message: '请选择是否同步至企业微信',
      trigger: 'change'
    },
    leaveOfficeTime: {
      required: true,
      message: this.$t('lang_please_select_date'),
      trigger: 'blur'
    },
    defaultCompanyId: {
      required: true,
      validator: this.validDefaultCompanyId,
      trigger: 'change'
    }
  };

  created() {
    if (this.currtent) {
      // 离职 | 删除
      if (this.flag != 'on') {
        this.personData = Object.assign({}, this.currtent);
        this.perosonCompanyList = [];
        this.personPlatformList = [];
        this.getAllPersonData();
        this.getCompanyList();
      } else {
        // 在职
        this.getPersonDetail();
        this.getDeptGroupSelectList(this.currtent.companyId);
        this.getDutySelectList(this.currtent.companyId);
      }
    }
  }

  /**
   * 验证主公司
   */
  validDefaultCompanyId(f, value, cb) {
    if (this.personData.defaultCompanyId) {
      cb();
    } else {
      cb(new Error(this.$t('lang_please_choose_to_change_the_main company') as any));
    }
  }

  /**
   * 获取详情
   */
  getPersonDetail() {
    let params = {
      companyId: this.currtent.companyId,
      personId: this.currtent.personId,
      idCard: this.currtent.idCard || ''
    };
    personDetails(params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.personData = res.data;
        if (res.data.takeOffices && res.data.takeOffices.length > 0) {
          let flag = false;
          // 判断是否存在主管部门
          res.data.takeOffices.forEach((item) => {
            if (item.defaultAble && item.status == '1') {
              flag = true;
            }
          });
          res.data.takeOffices.forEach((item) => {
            if (item.id == this.currtent.takeOfficeInfoId) {
              item.status = '1';
              // 如果有就改为false没有改为true
              item.defaultAble = flag ? false : true;
              this.takeOffices.push(item);
            }
          });
        }
      } else {
        this.$message.error(res.message);
      }
    });
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children
    };
    return params;
  }

  /**
   * 获取所有用户信息
   */
  getAllPersonData() {
    let params = {
      companyId: this.currtent.companyId,
      personId: this.currtent.personId,
      userIds: [this.currtent.userId]
    };
    getUserAllTakeOffices(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data && res.data.length > 0) {
        res.data.forEach((item) => {
          if (item.currentFlag == '1') {
            this.perosonCompanyList.push(item);
          } else if (item.currentFlag == '0') {
            this.personPlatformList.push(item);
          }
        });
      }
    });
  }

  /**
   * 离职 | 删除操作选择
   */
  allChange(val) {
    if (val) {
      this.personData.defaultCompanyId = null;
    }
  }

  /**
   * 获取部门职务名称
   */
  getDeptName(list, id) {
    for (let i = 0; i < list.length; i++) {
      let a = list[i];
      if (a.id === id) {
        return a.name;
      } else {
        if (a.children && a.children.length > 0) {
          let res = this.getDeptName(a.children, id);
          if (res) {
            return res;
          }
        }
      }
    }
  }

  // 获取部门下拉列表
  getDeptGroupSelectList(companyId) {
    getCompanyTreeData({
      parentId: companyId,
      types: ['0', '1'],
      cascadeType: '2',
      tenantIgnore: true
    })
      .then((res: Record<any, any>) => {
        this.deptListData = res.data;
      })
      .finally(() => {});
  }

  // 获取职务下拉列表
  getDutySelectList(companyId) {
    getCompanyTreeData({
      parentId: companyId,
      types: ['2'],
      cascadeType: '2',
      tenantIgnore: true
    })
      .then((res: Record<any, any>) => {
        this.dutyListData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 保存
   */
  save() {
    this.formRef.clearValidate();
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      if (this.flag != 'on') {
        this.saveOutOrDelete();
      } else {
        this.saveOn();
      }
    });
  }

  /**
   * 在职保存
   */
  saveOn() {
    this.personData.takeOffices.forEach((item) => {
      if (item.id == this.currtent.takeOfficeInfoId) {
        item.takeOfficeReason = this.takeOfficeReason;
      }
    });
    addOrEditPerson(this.personData)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
          this.$emit('success');
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        // 关闭加载框
      });
  }

  /**
   * 保存离职或删除
   */
  saveOutOrDelete() {
    // 离职
    if (this.flag == 'out') {
      this.personData.status = '2';
    } else if (this.flag == 'del') {
      // 删除
      this.personData.status = '3';
    }
    if (this.personData.all) {
      this.personData.all = this.personData.all == '0' ? false : true;
    }

    takeOfficeOp(this.personData).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        // 关闭弹窗
        this.handleCloseDialog();
        this.$emit('success');
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 编辑时获取主公司列表
   */
  getCompanyList() {
    getCompanys({ userId: this.currtent.userId }).then((res: Record<any, any>) => {
      if (res.code == '1') {
        // 去掉当前公司
        res.data.forEach((item, index) => {
          if (item.id == this.currtent.companyId) {
            res.data.splice(index, 1);
          }
        });
        this.mainCompanyList = res.data;
      }
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
