var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent", staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.companyData,
                      "default-expand-all": "",
                      "current-node-key": _vm.currentNodeKey,
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-tree-node-title" },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "page-search-bar" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.formSearchModel,
                      inline: "",
                      "label-suffix": ": ",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("lang_department") } },
                      [
                        _c("treeselect", {
                          attrs: {
                            options: _vm.depts,
                            "append-to-body": "",
                            "z-index": 2999,
                            normalizer: _vm.treeNormalizer,
                            "default-expand-level": 1,
                            placeholder: "",
                          },
                          model: {
                            value: _vm.formSearchModel.deptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearchModel, "deptId", $$v)
                            },
                            expression: "formSearchModel.deptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mgb-0",
                        attrs: { label: _vm.$t("lang_user_name") },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSearch($event)
                            },
                          },
                          model: {
                            value: _vm.formSearchModel.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearchModel, "name", $$v)
                            },
                            expression: "formSearchModel.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mgb-0" },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleSearch },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-refresh",
                              },
                              on: { click: _vm.handleSearchReset },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  background: "#fff",
                  height: "calc(100% - 62px)",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingOuter,
                        expression: "loadingOuter",
                      },
                    ],
                    staticClass: "page-pagetable",
                    attrs: {
                      border: "",
                      data: _vm.formListData,
                      height: "calc(100% - 70px)",
                      "empty-text": _vm.$t("lang_no_data"),
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_company_name"),
                        align: "center",
                        prop: "companyName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_department_name"),
                        align: "center",
                        prop: "deptName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_title"),
                        align: "center",
                        prop: "dutyName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_full_name"),
                        align: "center",
                        prop: "name",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_account"),
                        align: "center",
                        prop: "account",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_on_the_job_status"),
                        align: "center",
                        prop: "status",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(_vm.$t("lang_in_office"))),
                                  ])
                                : _vm._e(),
                              scope.row.status == "2"
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(_vm._s(_vm.$t("lang_leave_office"))),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_operation"),
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status != "1"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "#007aff",
                                        "margin-left": "10px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowClick(scope.row, "on")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_on_the_job")))]
                                  )
                                : _vm._e(),
                              scope.row.status != "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "#007aff",
                                        "margin-left": "10px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowClick(scope.row, "out")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_quit")))]
                                  )
                                : _vm._e(),
                              scope.row.status != "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "#e03e2d",
                                        "margin-left": "10px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowClick(scope.row, "del")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  staticClass: "page-pager",
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.changePageSize,
                    "current-change": _vm.changePage,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.isShowDiaoLog
        ? _c("status-form", {
            attrs: {
              visible: _vm.isShowDiaoLog,
              flag: _vm.flag,
              currtent: _vm.currtent,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowDiaoLog = $event
              },
              success: _vm.getPersonPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }