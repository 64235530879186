var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "75%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title:
          _vm.flag == "on"
            ? _vm.$t("lang_personnel_in_service")
            : _vm.flag == "out"
            ? _vm.$t("lang_personnel_turnover")
            : _vm.$t("lang_personnel_deletion"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _vm.personData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _vm.flag == "del" || _vm.flag == "out"
              ? _c(
                  "div",
                  [
                    _vm.perosonCompanyList.length > 0
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "text_box" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.flag == "out"
                                      ? _vm.$t("lang_quit")
                                      : _vm.$t("lang_delete")
                                  ) +
                                    _vm._s(
                                      _vm.$t(
                                        "lang_current_company_information_of_personnel"
                                      )
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "text_box_line" }),
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: _vm.perosonCompanyList,
                                  border: "",
                                  "empty-text": _vm.$t("lang_no_data"),
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_working_company"),
                                    align: "center",
                                    prop: "companyName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_working_dept"),
                                    align: "center",
                                    prop: "deptName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_duty"),
                                    align: "center",
                                    prop: "dutyName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_tenure"),
                                    align: "center",
                                    prop: "takeOfficeTime",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_departure_time"),
                                    align: "center",
                                    prop: "leaveOfficeTime",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t(
                                      "lang_reason_for_leaving_office"
                                    ),
                                    align: "center",
                                    prop: "leaveOfficeReason",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_employment_status"),
                                    align: "center",
                                    prop: "status",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.status == "1"
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lang_in_office")
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.status == "2"
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lang_leave_office"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.status == "3"
                                              ? _c(
                                                  "el-tag",
                                                  { attrs: { type: "danger" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lang_delete")
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    15144622
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.personPlatformList.length > 0
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "text_box" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.flag == "out"
                                      ? _vm.$t("lang_quit")
                                      : _vm.$t("lang_delete")
                                  ) +
                                    _vm._s(
                                      _vm.$t(
                                        "lang_current_platform_information_of_personnel"
                                      )
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "text_box_line" }),
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: _vm.personPlatformList,
                                  border: "",
                                  "empty-text": _vm.$t("lang_no_data"),
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_working_company"),
                                    align: "center",
                                    prop: "companyName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_working_dept"),
                                    align: "center",
                                    prop: "deptName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_duty"),
                                    align: "center",
                                    prop: "dutyName",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_tenure"),
                                    align: "center",
                                    prop: "takeOfficeTime",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_departure_time"),
                                    align: "center",
                                    prop: "leaveOfficeTime",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t(
                                      "lang_reason_for_leaving_office"
                                    ),
                                    align: "center",
                                    prop: "leaveOfficeReason",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("lang_employment_status"),
                                    align: "center",
                                    prop: "status",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.status == "1"
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "success" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lang_in_office")
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.status == "2"
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "warning" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lang_leave_office"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.status == "3"
                                              ? _c(
                                                  "el-tag",
                                                  { attrs: { type: "danger" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("lang_delete")
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    15144622
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.flag == "del" || _vm.flag == "out"
                      ? _c(
                          "el-row",
                          [
                            _c("div", { staticClass: "text_box" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.flag == "out"
                                      ? _vm.$t("lang_quit")
                                      : _vm.$t("lang_delete")
                                  ) + _vm._s(_vm.$t("lang_information"))
                                ),
                              ]),
                              _vm.flag == "del"
                                ? _c("span", { staticClass: "delete" }, [
                                    _vm._v("（员工一旦删除后将无法恢复）"),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "text_box_line" }),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lang_full_name"),
                                          prop: "name",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            placeholder:
                                              _vm.$t("lang_please_enter"),
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.personData.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personData,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "personData.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lang_account"),
                                          prop: "account",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              _vm.$t("lang_please_enter"),
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.personData.account,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personData,
                                                "account",
                                                $$v
                                              )
                                            },
                                            expression: "personData.account",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_whether_the_main_company"
                                          ),
                                          prop: "mainCompany",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.personData.mainCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.personData,
                                                  "mainCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "personData.mainCompany",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: _vm.$t("lang_yes"),
                                                value: true,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: _vm.$t("lang_no"),
                                                value: false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.flag == "out"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_resignation_operation"
                                              ),
                                              prop: "all",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                on: { change: _vm.allChange },
                                                model: {
                                                  value: _vm.personData.all,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personData,
                                                      "all",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personData.all",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "lang_leaving_the_current_company"
                                                    ),
                                                    value: "0",
                                                  },
                                                }),
                                                _vm.personData.mainCompany &&
                                                _vm.mainCompanyList.length > 0
                                                  ? _c("el-option", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "lang_all_jobs_in_the_resignation_system"
                                                        ),
                                                        value: "1",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.flag == "del"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_delete_operation"
                                              ),
                                              prop: "all",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                on: { change: _vm.allChange },
                                                model: {
                                                  value: _vm.personData.all,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personData,
                                                      "all",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "personData.all",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "lang_delete_current_company"
                                                    ),
                                                    value: "0",
                                                  },
                                                }),
                                                _vm.personData.mainCompany &&
                                                _vm.mainCompanyList.length > 0
                                                  ? _c("el-option", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "lang_delete_all_jobs_in_the_system"
                                                        ),
                                                        value: "1",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _vm.flag == "out"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_date_of_resignation"
                                              ),
                                              prop: "leaveOfficeTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: _vm.$t(
                                                  "lang_please_select_date"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.personData
                                                    .leaveOfficeTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.personData,
                                                    "leaveOfficeTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "personData.leaveOfficeTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.flag == "del"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_date_of_delete"
                                              ),
                                              prop: "leaveOfficeTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: _vm.$t(
                                                  "lang_please_select_date"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.personData
                                                    .leaveOfficeTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.personData,
                                                    "leaveOfficeTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "personData.leaveOfficeTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.personData.mainCompany &&
                                _vm.mainCompanyList.length > 0 &&
                                _vm.personData.all == "0"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "lang_change_the_main_company"
                                              ),
                                              prop: "defaultCompanyId",
                                            },
                                          },
                                          [
                                            _c("treeselect", {
                                              attrs: {
                                                options: _vm.mainCompanyList,
                                                "append-to-body": "",
                                                "z-index": 2999,
                                                "default-expand-level": 1,
                                                normalizer:
                                                  _vm.menuTreeNormalizer,
                                                placeholder:
                                                  _vm.$t("lang_please_select"),
                                              },
                                              model: {
                                                value:
                                                  _vm.personData
                                                    .defaultCompanyId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.personData,
                                                    "defaultCompanyId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "personData.defaultCompanyId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.personData.mainCompany
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "200px",
                                              label: "是否同步至企业微信",
                                              prop: "synWechat",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: { label: true },
                                                model: {
                                                  value:
                                                    _vm.personData.synWechat,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personData,
                                                      "synWechat",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personData.synWechat",
                                                },
                                              },
                                              [_vm._v("是")]
                                            ),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: { label: false },
                                                model: {
                                                  value:
                                                    _vm.personData.synWechat,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.personData,
                                                      "synWechat",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "personData.synWechat",
                                                },
                                              },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _vm.flag == "out"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_reason_for_resignation"
                                          ),
                                          prop: "leaveOfficeReason",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "textarea", rows: 4 },
                                          model: {
                                            value:
                                              _vm.personData.leaveOfficeReason,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personData,
                                                "leaveOfficeReason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personData.leaveOfficeReason",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.flag == "del"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lang_reason_for_deletion"
                                          ),
                                          prop: "leaveOfficeReason",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "textarea", rows: 4 },
                                          model: {
                                            value:
                                              _vm.personData.leaveOfficeReason,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personData,
                                                "leaveOfficeReason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personData.leaveOfficeReason",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.flag == "on"
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "text_box" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("lang_base_info")))]),
                    ]),
                    _c("div", { staticClass: "text_box_line" }),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("lang_company_name"),
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "input",
                                  attrs: {
                                    placeholder: _vm.$t("lang_please_enter"),
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.currtent.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currtent, "companyName", $$v)
                                    },
                                    expression: "currtent.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("lang_full_name"),
                                  prop: "name",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "input",
                                  attrs: {
                                    placeholder: _vm.$t("lang_please_enter"),
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.currtent.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currtent, "name", $$v)
                                    },
                                    expression: "currtent.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("lang_account"),
                                  prop: "account",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$t("lang_please_enter"),
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.currtent.account,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currtent, "account", $$v)
                                    },
                                    expression: "currtent.account",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text_box" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("lang_employment_information"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "text_box_line" }),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.takeOffices,
                              border: "",
                              "empty-text": _vm.$t("lang_no_data"),
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_department"),
                                align: "center",
                                prop: "deptId",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getDeptName(
                                                _vm.deptListData,
                                                scope.row.deptId
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1756360987
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_duty"),
                                align: "center",
                                prop: "dutyId",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getDeptName(
                                                _vm.dutyListData,
                                                scope.row.dutyId
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2843035611
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "lang_is_it_the_competent_department"
                                ),
                                align: "center",
                                prop: "defaultAble",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.defaultAble
                                                ? _vm.$t("lang_yes")
                                                : _vm.$t("lang_no")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2996298999
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_tenure"),
                                align: "center",
                                prop: "takeOfficeTime",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("lang_reason_for_employment"),
                                align: "center",
                                prop: "takeOfficeReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text_box" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("lang_on_the_job_reasons"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "text_box_line" }),
                    _c(
                      "el-row",
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 4 },
                          model: {
                            value: _vm.takeOfficeReason,
                            callback: function ($$v) {
                              _vm.takeOfficeReason = $$v
                            },
                            expression: "takeOfficeReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }